/* sf-ui-display-medium-58646be638f96.otf */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap'); */
@font-face {
  font-family: myFirstFont;
  src: url('./assets/fonts/fonts-family/sf-ui-display-light-58646b33e0551.otf');
}

body {
  margin: 0;
  font-family: myFirstFont !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
thead {
  background: #012d53 !important;
  position: sticky;
  top: 0;
  z-index: 1;
}
thead tr th {
  background: #012d53 !important;
}
tfoot tr td {
  background: #012d53 !important;
}
tfoot {
  background: #012d53 !important;
  position: sticky;
  bottom: 0; 
}
.table-responsive.table-scroll {
  max-height: 600px;
}

table th, table td {
  white-space: nowrap;
}
.hover-underline:hover {
  text-decoration: underline;
}