.date-range-child {
    position: absolute;
    z-index: 10;
    right: 0;
}
.date-picker-container {
    position: relative;
}
.position-calendar {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
}
.rdrDefinedRangesWrapper {
    width: 150px;
}
.rdrDateDisplayWrapper,.rdrMonthAndYearWrapper {
    width: 450px;
}
.rdrMonth {
    width: 50%;
}
