.pagi-container {
    display: flex;
    list-style-type: none;
    user-select: none;
}
.pagi-container li {
   border: 1px solid #999;
   cursor: pointer;
   transition: 0.1s;
   color: #424242;
}
.pagi-container li a {
    display: block;
}
.pagi-container li:first-child {
    margin-right: 5px;
    border-radius: 5px;
    width: 60px;
    height: 35px;
    line-height: 32px;
    text-align: center;
    font-size: 16px;
}
.pagi-container li:last-child {
    margin-left: 5px;
    border-radius: 5px;
    width: 60px;
    height: 35px;
    line-height: 32px;
    text-align: center;
    font-size: 16px;
}
.pagi-container li:not(:first-child):not(:last-child) {
    border-radius: 5px;
    width: 40px;
    height: 35px;
    line-height: 32px;
    text-align: center;
    font-size: 14px;
}
.pagi-container li:last-child:hover,.pagi-container li:first-child:hover  {
    background-color: #012d53;
    color: #FFF;
}
.active {
    background-color: #012d53;
    color: #FFF !important;
}